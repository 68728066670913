export const MenuBar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color="#2ac3de"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 12L10 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 5L4 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 19L4 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
