import "./App.css";
import { BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Portafolio from "./page/Portafolio";
import DisenoWeb from "./page/DiseñoWeb";
import  Articulos from "./page/Articulos";
import ReactGA from "react-ga4";

ReactGA.initialize("G-PYWH9XQ6BN");

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/portafolio-web"/>} />
          <Route path="/portafolio-web" element={<Portafolio/>} />
          <Route path="/portafolio-diseno-web" element={<DisenoWeb/>} />
          <Route path="/contacto" element={<Articulos/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
