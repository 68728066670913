import { Enlace, SocialLink } from "../app/Enlaces";

export const Footer = ({ links }) => {
  return (
    <>
      <footer id="footer" className="text-white bg-customBackground">
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav className="flex flex-wrap justify-center space-x-5">
            {links.map((item, index) => (
              <Enlace key={index}  to={item.to}>{item.text}</Enlace>
            ))}
          </nav>

          <div className="flex justify-center mt-8 space-x-6">
            <SocialLink to="https://www.facebook.com/alexzen.dev">
              <i className="fab fa-facebook-f" />
            </SocialLink>
            <SocialLink to="https://www.instagram.com/alexzen.dev/">
              <i className="fab fa-instagram" />
            </SocialLink>
            <SocialLink to="https://twitter.com/alexzen_develop">
              <i className="fab fa-x-twitter" />
            </SocialLink>
            <SocialLink to="https://github.com/54Alexzen">
              <i className="fab fa-github" />
            </SocialLink>
            <SocialLink to="https://www.linkedin.com/in/alexzen-develop/">
              <i className="fab fa-linkedin" />
            </SocialLink>
          </div>
          <p className="mt-8 text-sm tracking-tighter text-center text-gray-400">
            © 2022, Creado por{" "}
            <span className="text-customBlue">alexzen.dev</span>.
          </p>
        </div>
      </footer>
    </>
  );
};
