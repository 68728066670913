import { Footer } from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Hero from "../components/portafolio/Hero";
import SEO from "../seo/SEO";
import { LinksPortafolioWeb } from "../utils/ConstantLinks";

export default function Portafolio() {
  return (
    <>
      <SEO
        title="Portafolio Web"
        description="Bienvenido a mi portafolio web, donde puedes encontrar una colección de mis proyectos de desarrollo web y descubre cómo puedo ayudarte a crear una presencia en línea impactante. ¡Contáctame!"
      />
      <Navbar />
      <main className="bg-customBackground">
        <Hero />
      </main>
      <Footer links={LinksPortafolioWeb} />
    </>
  );
}
