import { useEffect, useState } from "react";
import { Ruta } from "../app/Enlaces";
import { MenuBar } from "../assets/Icons";
import { motion } from "framer-motion";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setHasScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="w-full z-10">
      <nav
        className={`flex items-center justify-between lg:px-24 md:px-12 px-7 py-6 w-full fixed ${
          hasScrolled
            ? "bg-customBackground backdrop-blur-lg bg-opacity-60"
            : "bg-customBackground"
        } z-10`}
      >
        <div className="md:w-12 sm:w-10 w-9">
          <img
            src="/images/logo.png"
            alt="logo completo"
            className="object-cover"
          />
        </div>

        <div className="sm:flex hidden space-x-5">
          <Ruta to="/portafolio-web">Portafolio web</Ruta>
          <Ruta to="/portafolio-diseno-web">Portafolio de diseño</Ruta>
          <Ruta to="/contacto">Contacto</Ruta>
        </div>

        <motion.div whileTap={{ scale: 0.95 }} className={`sm:hidden flex w-8`}>
          <button className="w-full" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <MenuBar />
          </button>
        </motion.div>

        <div
          className={`w-full min-h-screen py-6 px-7 bg-customBackground backdrop-blur-lg bg-opacity-95 z-50 space-y-20 ${
            isMenuOpen ? "fixed inset-0 overflow-hidden" : "hidden"
          }`}
        >
          <div className="flex flex-row justify-between items-center">
            <div className="md:w-12 sm:w-10 w-9">
              <img
                src="/images/logo.png"
                alt="logo completo"
                className="object-cover"
              />
            </div>
            <button className="" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <i className="fas fa-xmark text-red-500 text-4xl" />
            </button>
          </div>

          <div className="flex flex-col w-full justify-center items-center space-y-5">
            <Ruta to="/portafolio-web">Portafolio web</Ruta>
            <Ruta to="/portafolio-diseno-web">Portafolio de diseño</Ruta>
            <Ruta to="/contacto">Contacto</Ruta>
          </div>
        </div>
      </nav>
    </header>
  );
}
