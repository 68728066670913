import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

export const Ruta = ({ to, children }) => {
  const location = useLocation();

  return (
    <Link
      to={to}
      className={`md:text-base sm:text-sm hover:text-customBlue tracking-tighter ${
        location.pathname === to
          ? "font-semibold text-customBlue border-b border-customBlue"
          : "text-white transition-colors duration-300"
      }`}
    >
      {children}
    </Link>
  );
};

export const Enlace = ({ to, children }) => {
  const location = useLocation();

  return (
    <a
      href={to}
      className={`sm:text-base text-sm hover:text-customBlue tracking-tighter ${
        location.pathname === to
          ? "font-semibold text-customBlue"
          : "text-white transition-colors duration-300"
      }`}
    >
      {children}
    </a>
  );
};

export const SocialLink = ({ to, children }) => {
  return (
    <motion.a
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      className={`w-7 h-7 border border-gray-400 rounded-full flex flex-row items-center justify-center text-gray-400 hover:border-customBlue hover:text-customBlue transition-colors duration-200 ease-in-out`}
    >
      {children}
    </motion.a>
  );
};
