import { DownloadButton } from "../app/Buttons";

export default function Hero() {

    return (
        <div className="min-h-screen flex flex-col items-center justify-center text-white">
            <div className="w-full flex justify-center ">
                    <img src="images/profile.jpeg" alt="Profile" className="rounded-full object-cover lg:w-72 lg:h-72 sm:w-60 sm:h-60 w-52 h-52" />
                </div>

                <div className="md:mt-10 mt-7">
                    <p className="lg:text-3xl text:2xl tracking-tighter text-center">¡Hola! , soy</p>
                    <h1 className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-customBlue to-customCyan py-3 lg:text-6xl text-4xl">
                        Alexis Jimenez
                    </h1>
                    <p className="lg:text-lg text-base text-center">Desarrollador web</p>
                </div>


                <div className="sm:mt-10 mt-7">
                    <DownloadButton />
                </div>
                <div className="sm:mt-16 mt-10">
                    <a href="#footer" className="animate-bounce flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform bg-gray-700 rounded-full hover:scale-110"
                    >
                        <i className="fas fa-angle-down"></i>
                    </a>
                </div>
        </div>
    );
}
