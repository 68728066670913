export const DownloadButton = () => {
  return (
    <button className="group relative h-12 w-56 overflow-hidden rounded-full bg-transparent text-xs font-semibold uppercase border-2 border-customBlue">
      <div className="absolute inset-0 w-11 bg-gradient-to-r from-customBlue to-customCyan rounded-full transition-all duration-[250ms] ease-out group-hover:w-full"></div>
      <div className="absolute inset-0 w-11 bg-customBlue rounded-full flex justify-center items-center">
        <i className="fas fa-download text-white"></i>
      </div>
      <div className="flex items-end justify-center ">
        <span className="relative text-customBlue group-hover:text-white">
          Descargar CV
        </span>
      </div>
    </button>
  );
};


export const ButtonBlue = ({children, to}) => {
  return (
      <>
      <a
          href={to}
          rel="noopener noreferrer"
          className="flex items-center justify-center rounded-full w-40 h-12 bg-customBlue hover:bg-gradient-to-r hover:from-customBlue hover:to-customCyan text-customBackground hover:ring-2 hover:ring-offset-2 hover:ring-offset-customBackground hover:ring-customBlue transition-all ease-out duration-300 text-xs font-semibold uppercase"
        >
          
          <span className="relative">{children}</span>
        </a>
      </>
  )
}