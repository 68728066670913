import { LinksDesignWeb } from "../../utils/ConstantLinks";
import { ButtonBlue } from "../app/Buttons";
import { Footer } from "./Footer";
import Navbar from "./Navbar";

export const ComingSoon = ({ title }) => {

  return (
    <div className="bg-customBackground">
      <Navbar />
      <section className="text-white flex flex-col justify-center items-center sm:min-h-screen min-h-[75vh] px-5">
        <p className="tracking-tighter lg:text-base sm:text-sm text-xs text-center">Sitio en construcción</p>
        <p className="lg:text-4xl sm:text-2xl text-lg text-center">Próximamente</p>
        <h1 className="text-center font-bold text-transparent bg-clip-text bg-gradient-to-r from-customBlue to-customCyan lg:pt-5 sm:pt-3 pt-5 lg:text-6xl sm:text-4xl text-3xl">
          {title}
        </h1>
        <div className="mt-10">
        <ButtonBlue to="/portafolio-web">Inicio</ButtonBlue>
        </div>
      </section>
      <Footer links={LinksDesignWeb}/>
    </div>
  );
};
